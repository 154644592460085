import Layout from "../components/layout";
import Seo from "../components/seo";
import React from "react";


const GoAksaraPostCore = () => {
    const post = { "id": "go-pkg-erp-core-v1", "title": "Golang Aksara ERP Core", "content": "" ,
        "pkg_url":"pkg.aksaratech.cloud/go-pkg-erp-core-v1",
        "repo_protocol": "https",
        "repo_url": "git.aksaratech.com",
        "pkg_repo_url": "aksaratech/services/packages/erp-core",
        "prod_branch": "main"
    }

    const repo_url = `${post.repo_protocol}://${post.repo_url}/${post.pkg_repo_url}`

    const buildMeta = [{
        name: "go-import",
        content: `${post.pkg_url} git ${repo_url}`
    },
        {
            name: "go-source",
            content:`${post.pkg_url} ${repo_url} ${repo_url}/tree/${post.prod_branch}{/dir} ${repo_url}/blob/${post.prod_branch}{/dir}/{file}#L{line}`
        }
    ]
    return (
        <Layout>
            <Seo title="Aksara Package " meta={buildMeta} />
            <h1>{post.title}</h1>
            <p>How to install</p>
            <code>
                go env -w GONOSUMDB='{post.repo_url},pkg.aksaratech.cloud'<br/>
                touch ~/.netrc <br/>
                echo machine {post.repo_url} login [username] password [token] > ~/.netrc<br/>
                go get -u {post.pkg_url}
            </code>
        </Layout>
    )
}

export default GoAksaraPostCore
